<script setup lang="ts">
import { CmsBlock, CmsSlot } from "@shopware-pwa/types";

type CmsBlockMaasTextImage = CmsBlock & {
  slots: Array<
    CmsSlot & {
      slot: "content";
    }
  >;
};

const props = defineProps<{
  content: CmsBlockMaasTextImage;
}>();

const { getSlotContent } = useCmsBlock(props.content);
const slot: any = getSlotContent("intro");

const { getConfigValue } = useCmsElementConfig(slot);
const title: string = getConfigValue("title");
const subtitle: string = getConfigValue("subtitle");
const color: string = getConfigValue("color");
const backgroundColor: string = getConfigValue("backgroundColor");
const icon: boolean = getConfigValue("icon");
</script>

<template>
  <div class="s-gutter" data-component="intro">
    <div class="c-width-default">
      <CmsElementIntro
        :title="title"
        :subtitle="subtitle"
        :color="color"
        :background-color="backgroundColor"
        :icon="icon"
      />
    </div>
  </div>
</template>
